.rj-marketboard {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 354px;
  width: 100%;
  transform: translate3d(0, 0, 0);
}
.rj-markerboard-markets {
  margin-bottom: 5px;
  height: 100%;
  background-color: #121212;
}
.rj-marketboard-overlay {
  position: fixed;
  top: 48px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: transparent;
}
sb-lazy-render {
  display: block;
  position: relative;
  box-sizing: border-box;
}
.rj-market::before {
  content: "";
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  height: 1px;
  background-color: rgba(36, 36, 36, 0.6);
}
sb-lazy-render:first-child .rj-market:first-child::before {
  display: none;
}
.rj-market__button {
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0 2px;
  align-items: center;
  overflow: hidden;
  border: none;
  background-color: #242424;
  color: #53D337;
  font-size: 12px;
  word-break: break-word;
  z-index: 0;
}
.rj-market__button:disabled {
  pointer-events: none;
  opacity: 1;
}
.rj-market__button:disabled .rj-market__checkmark {
  opacity: 0.6;
}
.rj-market__button.rj-odds-update-indicator {
  pointer-events: none;
  animation: rj-flash-outcome-cell-background 750ms linear;
}
.rj-market__button--grid-column-1 {
  -ms-grid-column: 1;
  grid-column: 1;
}
.rj-market__button--grid-column-2 {
  -ms-grid-column: 2;
  grid-column: 2;
}
.rj-market__button--grid-column-3 {
  -ms-grid-column: 3;
  grid-column: 3;
}
.rj-market__button--with-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rj-market__button-yourbet-title {
  display: block;
  position: relative;
  font-weight: bold;
  color: #53D337;
}
.rj-market__button-yourbet-title.rj-market__button-yourbet-title--loading {
  color: #000;
}
.rj-market__button-yourbet-title:not(:last-child) {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}
.rj-market__button-yourbet-title:not(:last-child).rj-market__button-yourbet-title--loading {
  color: #000;
}
.rj-market__button-dynamic-title {
  display: block;
  position: relative;
  font-weight: bold;
  color: #53D337;
  color: #F7D002;
}
.rj-market__button-dynamic-title.rj-market__button-yourbet-title--loading {
  color: #000;
}
.rj-market__button-dynamic-title:not(:last-child) {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
}
.rj-market__button-dynamic-title:not(:last-child).rj-market__button-yourbet-title--loading {
  color: #000;
}
.rj-market__button-dynamic-title:last-child {
  animation: rj-market__button__animate-odds-show 0.2s ease-in forwards;
}
.rj-market__button-dynamic-title.rj-market__button-dynamic-title--loading {
  color: #000;
}
.rj-market__button-dynamic-title.rj-market__button-dynamic-title--selecting {
  animation: rj-market__button__animate-odds-hide 0.2s ease-in forwards;
}
.rj-market__button--selected {
  background-color: #fff;
}
.rj-market__button--selected .rj-market__button-yourbet-title {
  color: #000;
}
.rj-market__button--selected .rj-market__button-dynamic-title {
  color: #000;
}
.rj-market__button--selected .rj-market__button-yourbet-odds {
  color: #000;
}
.rj-market__button-yourbet-odds {
  position: relative;
  display: block;
  font-weight: bold;
  padding-top: 4px;
  color: #53D337;
}
.rj-market__button-yourbet-odds.rj-market__button-yourbet-odds--loading {
  color: #000;
}
.rj-market__button-dynamic-odds {
  position: relative;
  display: block;
  font-weight: bold;
  padding-top: 4px;
  color: #53D337;
  margin-left: 13px;
  padding-top: 0;
  flex-shrink: 0;
  animation: rj-market__button__animate-odds-show 0.2s ease-in forwards;
  color: #F7D002;
}
.rj-market__button-dynamic-odds.rj-market__button-yourbet-odds--loading {
  color: #000;
}
.rj-market__button-dynamic-odds.rj-market__button-dynamic-odds--loading {
  color: #000;
}
.rj-market__button-dynamic-odds.rj-market__button-dynamic-odds--selecting {
  animation: rj-market__button__animate-odds-hide 0.2s ease-in forwards;
}
.rj-market__button--loading {
  background-color: #fff;
}
.rj-market__button--loading:disabled .rj-market__button-yourbet-title.rj-market__button-yourbet-title--loading,
.rj-market__button--loading:disabled .rj-market__button-dynamic-title.rj-market__button-dynamic-title--loading {
  color: #000;
}
.rj-market__button__animate-loading {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  background-color: #DADADA;
  animation: rj-market__button__animate-loading 1.6s ease-in infinite;
}
@keyframes rj-market__button__animate-loading {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}
@keyframes rj-flash-outcome-cell-background {
  0% {
    background-color: #242424;
  }
  50% {
    background-color: #ababab;
  }
  100% {
    background-color: #242424;
  }
}
@keyframes rj-market__button__animate-odds-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rj-market__button__animate-odds-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rj-market__group {
  display: flex;
  margin: 0;
  border: 0;
  align-items: center;
  background-color: transparent;
  color: #ababab;
  white-space: nowrap;
  text-transform: uppercase;
}
.rj-market__group[aria-selected="true"] {
  position: relative;
  color: #fff;
}
.rj-market__group-indicator {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #53D337;
}
.rj-market__group-indicator--dynamic-odds {
  background-color: #F7D002;
}
.rj-market__group-new-badge {
  padding-left: 8px;
}
button.rj-market__group {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-market__groups {
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.rj-market__groups-wrapper-inner {
  box-sizing: border-box;
}
.rj-market__header {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  align-items: center;
  padding-right: 56px;
  padding-left: 16px;
  width: 100%;
  min-height: 48px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
h2.rj-market__header {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-market__label {
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 6px;
  min-height: 24px;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: #121212;
  color: #C5C5C5;
  font-size: 9px;
  line-height: 16px;
  word-break: break-word;
}
.rj-market__label--row {
  padding: 16px 6px 16px 0px;
  height: 48px;
  font-size: 12px;
  color: #fff;
}
.rj-market__label--text-left {
  text-align: left;
}
.rj-market__label--text-center {
  padding-right: 2px;
  padding-left: 2px;
  text-align: center;
  font-weight: 600;
}
.rj-market__template {
  display: -ms-grid;
  display: grid;
  padding: 0 16px 16px 16px;
  grid-gap: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
}
.rj-market__template--1-column {
      grid-template-areas: ".";
}
.rj-market__template--2-columns {
      grid-template-areas: ". .";
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}
.rj-market__template--3-columns,
.rj-market__template--3-columns-no-row-labels,
.rj-market__template--3-columns-no-header-labels {
      grid-template-areas: ". . .";
  -ms-grid-columns: (1fr)[3];
  grid-template-columns: repeat(3, 1fr);
}
.rj-market__template--4-columns {
      grid-template-areas: ". . . .";
  -ms-grid-columns: 3fr (2fr)[3];
  grid-template-columns: 3fr repeat(3, 2fr);
}
.rj-market__template--4-columns-no-header-labels {
      grid-template-areas: ". . . .";
  -ms-grid-columns: (1fr)[4];
  grid-template-columns: repeat(4, 1fr);
}
.rj-market__template--row-label-long {
  -ms-grid-columns: 2fr 1fr;
      grid-template-columns: 2fr 1fr;
}
.rj-market__template--grid-fixed-location {
  grid-auto-flow: column;
}
.rj-market__template--3-columns-no-row-labels .rj-market__label:first-child {
  grid-column: span 1;
}
.rj-market__template--4-columns .rj-market__label:first-child:not(.rj-market__label--row) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
}
.rj-market__template--truncate-strings .rj-market__label--row,
.rj-market__template--truncate-strings .rj-market__button-yourbet-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rj-market__template--truncate-strings .rj-market__label--text-center .rj-market__label-inner {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
}
@media screen and (max-width: 320px) {
  .rj-market__template--4-columns {
        grid-template-areas: ". . . .";
    -ms-grid-columns: 2fr (2fr)[3];
    grid-template-columns: 2fr repeat(3, 2fr);
  }
}
.rj-marketboard-message {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  padding: 0 20px;
  font-size: 20px;
  text-align: center;
  line-height: 28px;
  font-weight: bold;
  color: #fff;
  transform: translateY(-50%);
}
.rj-market-collapsible__trigger {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  display: block;
  background-color: #121212;
  z-index: 1;
}
.rj-market-collapsible__trigger-button {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #121212;
}
.rj-market-collapsible__content {
  position: relative;
  overflow: hidden;
}
.rj-market-collapsible-arrow {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.rj-market-collapsible-arrow-inner {
  width: 13px;
  height: 13px;
  transition: transform 0.2s ease-in-out;
}
.rj-market-collapsible-arrow-inner:before,
.rj-market-collapsible-arrow-inner:after {
  position: absolute;
  content: '';
  background-color: #C5C5C5;
  top: 5.5px;
  height: 2px;
  width: 9.10399981px;
}
.rj-market-collapsible-arrow-inner:before {
  left: -0.62614312px;
  transform: rotate(-45deg);
}
.rj-market-collapsible-arrow-inner:after {
  left: 4.45964331px;
  transform: rotate(45deg);
}
.rj-market-collapsible-arrow-inner--down {
  transform: rotate(180deg);
}
.rj-market__tooltip {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 17px 6px 15px 8px;
}
.rj-market__tooltip[aria-expanded="true"] .rj-market__tooltip-icon {
  background-image: url("images/alt/info.svg");
}
.rj-market__tooltip-icon {
  width: 16px;
  height: 16px;
  background-image: url("images/info.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.rj-market__tooltip-text {
  box-sizing: border-box;
  margin: 0;
  padding: 8px 16px 16px;
  width: 100%;
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  color: #ababab;
  background-color: #121212;
  z-index: 1;
}
h3.rj-marketboard-prepacks__error-title {
  margin: 0 0 8px 0;
  color: #fff;
  font-family: 'Saira Condensed', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
}
.rj-marketboard-prepacks__error-info {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.rj-marketboard-prepacks__error-button {
  margin-top: 32px;
  background-color: #53D337;
  color: #121212;
  border: 0;
  border-radius: 4px;
  font-weight: 700;
}
.rj-marketboard-pre-packs-placeholder {
  width: 100%;
}
.rj-marketboard-pre-packs-placeholder__tabs {
  display: flex;
}
.rj-marketboard-pre-packs-placeholder__tab {
  animation: rj-marketboard-pre-packs-loading 1200ms infinite;
}
.rj-marketboard-pre-packs-placeholder__tab:nth-child(1) {
  margin-left: 16px;
}
.rj-marketboard-pre-packs-placeholder__component:not(:last-child):after {
  content: "";
  display: block;
  margin-top: 16px;
  height: 1px;
  background-color: #373737;
  animation: rj-marketboard-pre-packs-loading 1200ms infinite;
}
.rj-marketboard-pre-packs-placeholder__component:last-child {
  padding-bottom: 16px;
}
.rj-marketboard-pre-packs-placeholder__button {
  margin-top: 22px;
  background-color: #373737;
  animation: rj-marketboard-pre-packs-loading 1200ms infinite;
}
.rj-marketboard-pre-packs-placeholder__rect {
  height: 9px;
  border-radius: 2px;
  background-color: #373737;
  animation: rj-marketboard-pre-packs-loading 1200ms infinite;
}
.rj-marketboard-pre-packs-placeholder__rect:not(:first-child) {
  margin-top: 15px;
}
@keyframes rj-marketboard-pre-packs-loading {
  0% {
    opacity: 30%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    opacity: 30%;
  }
}
.rj-market__checkmark {
  animation: rj-market__checkmark__animate-showing 0.2s alternate ease-in;
}
@keyframes rj-market__checkmark__animate-showing {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}
.rj-marketboard-placeholder {
  width: 100%;
}
.rj-marketboard-placeholder__item {
  background-color: #121212;
  overflow: hidden;
}
.rj-marketboard-placeholder__item--groups {
  box-sizing: border-box;
  padding-left: 16px;
  margin-bottom: 3px;
  height: 36px;
}
.rj-marketboard-placeholder__item--event-data {
  height: 32px;
}
.rj-marketboard-placeholder__item--market {
  position: relative;
}
.rj-marketboard-placeholder__item--market:after {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  right: 8px;
  bottom: 0;
  left: 8px;
  background-color: #242424;
}
.rj-marketboard-placeholder__item-inner {
  width: 100%;
  height: 100%;
  animation: rj-marketboard-loading 1200ms infinite;
}
.rj-marketboard-placeholder__item-inner--web-background {
  background: linear-gradient(to right, rgba(51, 51, 51, 0.6) 0%, rgba(21, 21, 21, 0.9) 50%, rgba(51, 51, 51, 0.6) 75%, rgba(51, 51, 51, 0.6) 100%);
  background-size: 200% 200%;
}
.rj-marketboard-placeholder__item-inner--groups {
  -webkit-clip-path: url(#clip-path-sgp-placeholder-groups);
          clip-path: url(#clip-path-sgp-placeholder-groups);
}
.rj-marketboard-placeholder__item-inner--event-data {
  -webkit-clip-path: url(#clip-path-sgp-placeholder-event-data);
          clip-path: url(#clip-path-sgp-placeholder-event-data);
}
.rj-marketboard-placeholder__item-inner--parlay-card {
  -webkit-clip-path: url(#clip-path-sgp-placeholder-parlay-card);
          clip-path: url(#clip-path-sgp-placeholder-parlay-card);
}
.rj-marketboard-placeholder__item-inner--regular-card {
  -webkit-clip-path: url(#clip-path-sgp-placeholder-regular-card);
          clip-path: url(#clip-path-sgp-placeholder-regular-card);
}
.rj-marketboard-placeholder__item-inner--regular-card,
.rj-marketboard-placeholder__item-inner--parlay-card {
  height: auto;
}
.rj-marketboard-placeholder__rect {
  background-color: rgba(55, 55, 55, 0.7);
  border-radius: 2px;
  position: absolute;
}
.rj-marketboard-placeholder__market-header {
  width: 100%;
  height: 53px;
  background-color: #121212;
  position: relative;
}
.rj-marketboard-placeholder__market-header--title {
  position: absolute;
  left: 4vw;
  top: 21px;
  width: 90px;
  height: 12px;
  background-color: #373737;
  opacity: 0.7;
  border-radius: 3px;
}
.rj-marketboard-placeholder__parlay-card {
  width: 100%;
  height: 131px;
  background-color: #121212;
  position: relative;
}
.rj-marketboard-placeholder__parlay-card--separator {
  position: absolute;
  left: 4vw;
  right: 16px;
  top: 130px;
  height: 1px;
  background-color: #242424;
}
.rj-marketboard-placeholder__parlay-card--title {
  width: 12vw;
  height: 9px;
  top: 4px;
  border-radius: 2px;
}
.rj-marketboard-placeholder__parlay-card--title-1 {
  left: 44vw;
}
.rj-marketboard-placeholder__parlay-card--title-2 {
  left: calc(44vw + 18vw + 2px);
}
.rj-marketboard-placeholder__parlay-card--title-3 {
  left: calc(44vw + 36vw + 4px);
}
.rj-marketboard-placeholder__parlay-card--market {
  width: 18vw;
  height: 44px;
  border-radius: 2px;
}
.rj-marketboard-placeholder__parlay-card--first-row {
  top: 24px;
}
.rj-marketboard-placeholder__parlay-card--second-row {
  top: 78px;
}
.rj-marketboard-placeholder__parlay-card--market-1,
.rj-marketboard-placeholder__parlay-card--market-4 {
  left: 41vw;
}
.rj-marketboard-placeholder__parlay-card--market-2,
.rj-marketboard-placeholder__parlay-card--market-5 {
  left: calc(41vw + 18vw + 2px);
}
.rj-marketboard-placeholder__parlay-card--market-3,
.rj-marketboard-placeholder__parlay-card--market-6 {
  left: calc(41vw + 36vw + 4px);
}
.rj-marketboard-placeholder__parlay-card--market-participant {
  left: 4vw;
  width: 20vw;
  height: 9px;
  border-radius: 2px;
  background-color: rgba(55, 55, 55, 0.7);
  position: absolute;
}
.rj-marketboard-placeholder__parlay-card--market-participant-1 {
  top: 41px;
}
.rj-marketboard-placeholder__parlay-card--market-participant-2 {
  top: 87px;
}
.rj-marketboard-placeholder__regular-card-container {
  width: 100%;
  height: 340px;
  position: relative;
  margin-top: 8px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card {
  width: 46vw;
  height: 52px;
  background-color: #242424;
  border-radius: 4px;
  position: absolute;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card--first-column {
  left: 4vw;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card--second-column {
  left: calc(50vw + 2px);
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-1,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-7 {
  top: 0px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-2,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-8 {
  top: 56px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-3,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-9 {
  top: 112px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-4,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-10 {
  top: 168px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-5,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-11 {
  top: 224px;
}
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-6,
.rj-marketboard-placeholder__regular-card-container .rj-marketboard-placeholder__regular-card-12 {
  top: 280px;
}
@keyframes rj-marketboard-loading {
  0% {
    opacity: 30%;
  }
  50% {
    opacity: 70%;
  }
  100% {
    opacity: 30%;
  }
}
.rj-same-game-parlay {
  position: relative;
  height: 100%;
}
.rj-same-game-parlay .rj-prepacks-filters {
  top: 49px;
  padding-top: 8px;
}
.rj-same-game-parlay .rj-prepacks-no-bets-screen {
  height: calc(100vh - 403px);
}
.rj-same-game-parlay .rj-prepacks-filters + .rj-prepacks-no-bets-screen {
  height: calc(100vh - 467px);
}
.rj-market__button:not(.rj-market__button--empty) {
  cursor: pointer;
}
.rj-market__button {
  display: flex;
  justify-content: center;
  height: 48px;
  line-height: 16px;
}
.rj-market__button:disabled .rj-market__button-yourbet-title,
.rj-market__button:disabled .rj-market__button-dynamic-title {
  color: rgba(255, 255, 255, 0.6);
}
.rj-market__button:disabled .rj-market__button-yourbet-odds,
.rj-market__button:disabled .rj-market__button-dynamic-odds {
  color: rgba(255, 255, 255, 0.6);
}
.rj-market__button--selected:disabled .rj-market__button-yourbet-title,
.rj-market__button--selected:disabled .rj-market__button-dynamic-title {
  color: rgba(0, 0, 0, 0.6);
}
.rj-market__button--selected:disabled .rj-market__button-yourbet-odds,
.rj-market__button--selected:disabled .rj-market__button-dynamic-odds {
  color: rgba(0, 0, 0, 0.6);
}
.rj-market__button-yourbet-odds {
  margin-left: 13px;
  padding-top: 0;
  flex-shrink: 0;
}
.rj-market-collapsible__trigger {
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 0;
  text-align: unset;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #121212;
  z-index: unset;
}
.rj-market-collapsible__trigger:hover {
  cursor: pointer;
}
.rj-market-collapsible__trigger:hover .rj-market-collapsible-arrow {
  display: block;
}
.rj-market-collapsible-arrow {
  display: none;
}
.rj-marketboard-message {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  position: static;
  transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}
.rj-market::before {
  display: none;
}
.rj-market__group {
  padding: 16px 8px 15px 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: color 0.16s linear;
}
.rj-market__group:hover {
  color: #fff;
}
.rj-market__groups {
  gap: 0 8px;
}
.rj-market__groups-wrapper {
  box-sizing: border-box;
  position: relative;
  background-color: #121212;
  transform: translate3d(0, 0, 0);
}
.rj-market__groups-wrapper-inner {
  margin-bottom: 16px;
  background-color: #121212;
  overflow: hidden;
}
.rj-market__groups-separator {
  position: absolute;
  margin: 0;
  border: 0;
  width: 100%;
  height: 1px;
  top: 51px;
  background-color: rgba(36, 36, 36, 0.6);
  z-index: 1;
}
.rj-market__header {
  min-height: 35px;
  padding-left: 8px;
  font-weight: bold;
  font-size: 12px;
  text-transform: unset;
}
h2.rj-market__header {
  font-family: inherit;
}
.rj-market__template {
  padding-right: 0;
  padding-bottom: 10px;
  padding-left: 0;
  grid-gap: 2px;
}
.rj-market__label--row {
  padding-left: 8px;
}
.rj-market__label--text-left {
  font-weight: bold;
}
.rj-market__label--text-center {
  text-transform: uppercase;
}
.rj-marketboard-prepacks__error-button {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 16px;
}
.rj-marketboard-prepacks__error {
  margin: 64px auto auto;
  width: 100%;
  text-align: center;
}
h3.rj-marketboard-prepacks__error-title {
  font-family: 'Saira Condensed', sans-serif;
}
.rj-marketboard-pre-packs-placeholder {
  background-color: #121212;
}
.rj-marketboard-pre-packs-placeholder__tabs {
  padding: 13px 0 14px;
}
.rj-marketboard-pre-packs-placeholder__tab {
  margin-right: 16px;
  margin-left: 16px;
  width: 70px;
  height: 9px;
  border-radius: 2px;
  background-color: #373737;
}
.rj-marketboard-pre-packs-placeholder__component {
  padding: 21px 8px 0px 8px;
}
.rj-marketboard-pre-packs-placeholder__button {
  height: 44px;
  margin: 22px 0 0;
}
.rj-marketboard-pre-packs-placeholder__rect {
  margin-left: 8px;
  width: 210px;
}
.rj-market__checkmark {
  padding-bottom: 3px;
}
.rj-market__checkmark--dual-view {
  padding-left: 13px;
}
.rj-marketboard {
  position: relative;
  padding-bottom: 0;
}
.rj-marketboard .rj-prepacksboard-overlay {
  background-color: transparent;
}
.rj-markerboard-markets {
  padding-bottom: 0;
}
.rj-marketboard-overlay {
  position: fixed;
  top: 52px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #121212;
  opacity: 0.5;
  z-index: 4;
}
.rj-market__tooltip-web {
  position: relative;
  display: inline-block;
}
.rj-market__tooltip-web:hover .rj-market__tooltip-web-text {
  display: block;
}
.rj-market__tooltip-web-icon {
  display: inline-block;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  background-image: url("images/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: text-top;
}
.rj-market__tooltip-web-text {
  box-sizing: border-box;
  position: absolute;
  display: none;
  margin: 0;
  padding: 8px 12px;
  top: 25px;
  left: -6px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 375px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  color: #fff;
  background-color: #373737;
  z-index: 1;
}
.rj-market__tooltip-web-text::before {
  content: "";
  position: absolute;
  display: block;
  top: -5px;
  left: 16px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top: 0;
  border-bottom: 5px solid #373737;
}
sb-lazy-render:last-child .rj-market:last-child [data-collapsed="true"] .rj-market__tooltip-web-text {
  top: auto;
  bottom: 25px;
}
sb-lazy-render:last-child .rj-market:last-child [data-collapsed="true"] .rj-market__tooltip-web-text::before {
  top: auto;
  bottom: -5px;
  border-bottom: 0;
  border-top: 5px solid #373737;
}
.rj-odds-update-arrow {
  position: absolute;
  top: 2px;
  right: 2px;
}
.rj-odds-update-arrow--up {
  animation: rj-flash-outcome-cell-arrow 750ms linear both;
  width: 10px;
  height: 10px;
}
.rj-odds-update-arrow--up:before,
.rj-odds-update-arrow--up:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  top: 4px;
  height: 2px;
  width: 6.98267946px;
}
.rj-odds-update-arrow--up:before {
  left: -0.31548295px;
  transform: rotate(-45deg);
}
.rj-odds-update-arrow--up:after {
  left: 3.27030349px;
  transform: rotate(45deg);
}
.rj-odds-update-arrow--down {
  animation: rj-flash-outcome-cell-arrow 750ms linear both;
  width: 10px;
  height: 10px;
}
.rj-odds-update-arrow--down:before,
.rj-odds-update-arrow--down:after {
  position: absolute;
  content: '';
  background-color: #53D337;
  bottom: 4px;
  height: 2px;
  width: 6.98267946px;
}
.rj-odds-update-arrow--down:before {
  left: -0.31548295px;
  transform: rotate(45deg);
}
.rj-odds-update-arrow--down:after {
  left: 3.27030349px;
  transform: rotate(-45deg);
}
@keyframes rj-flash-outcome-cell-arrow {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.side-arrow {
  position: absolute;
  top: 50%;
  padding: 3px 0;
  width: 53px;
  height: 32px;
  border: 0;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
}
.side-arrow--left {
  background: linear-gradient(to left, rgba(18, 18, 18, 0), #121212);
  text-align: left;
  left: 0;
}
.side-arrow--right {
  background: linear-gradient(to right, rgba(18, 18, 18, 0), #121212);
  text-align: right;
  right: 0;
}
.side-arrow:hover .side-arrow-icon {
  opacity: 1;
}
.side-arrow-icon {
  opacity: 0.6;
}
[data-hidden=true] .rj-same-game-parlay-web * {
  visibility: hidden;
}
.rj-same-game-parlay-web .rj-prepacks-filters-wrapper {
  margin-bottom: 16px;
  padding: 0;
}
.rj-same-game-parlay-web .side-arrow--left {
  background: linear-gradient(to left, rgba(18, 18, 18, 0), #121212);
}
.rj-same-game-parlay-web .side-arrow--right {
  background: linear-gradient(to right, rgba(18, 18, 18, 0), #121212);
}
.rj-same-game-parlay-web .rj-prepacks-filters-wrapper,
.rj-same-game-parlay-web .rj-prepacks-filter {
  background-color: #121212;
}
.rj-same-game-parlay-web {
  position: relative;
  padding: 0 16px;
  color: #fff;
  background-color: #121212;
}
.rj-same-game-parlay-web * {
  font-family: inherit;
}
.rj-same-game-parlay-web [aria-expanded="true"] {
  position: relative;
}
